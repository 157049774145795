
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import SuccessCheck from '@/components/SuccessCheck.vue';
import QRCodeReader from '@/components/QRCodeReader.vue';
import giftCardService from '../services/giftCards/giftCardService';

@Component({
  components: {
    SuccessCheck,
    QRCodeReader,
  },
})
export default class CodeResultSuccess extends Vue {
  public wantToWriteCode = false;
  public codeCard = '';
  public isScannerDisplayed = false;
  public countCardCredited = 1;

  get giftCard(): any | null {
    return this.$store.getters['giftCardModule/card'];
  }

  created(): void {
    if (!this.giftCard) {
      this.$router.push({
        name: 'Home',
      });
    }
  }

  get giftCardCount(): any | null {
    return this.$store.getters['giftCardModule/count'];
  }

  formatDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }

  async onDecode(code: string): Promise<void> {
    try {
      this.isScannerDisplayed = false;
      await giftCardService.creditGiftCard(code, {
        credit: this.giftCard.credit,
        clientCategory: this.giftCard.clientCategory,
        clientName: this.giftCard.clientName,
        validityStartDate: this.giftCard.validityStartDate as any,
        validityEndDate: this.giftCard.validityEndDate as any,
      });
      this.countCardCredited += 1;
      this.$buefy.toast.open({
        message: 'Carte créditée avec succès',
        type: 'is-success',
      });
      this.codeCard = '';
    } catch (error: any) {
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'error' } });
      this.$buefy.toast.open({
        message: 'Une erreur est survenue lors de la créditation de la carte',
        type: 'is-danger',
      });
    }
  }

  async submitCreditCard(): Promise<void> {
    const code = this.codeCard.replace('-', '');
    try {
      await giftCardService.creditGiftCard(code, {
        credit: this.giftCard.credit,
        clientCategory: this.giftCard.clientCategory,
        clientName: this.giftCard.clientName,
        validityStartDate: this.giftCard.validityStartDate as any,
        validityEndDate: this.giftCard.validityEndDate as any,
      });
      this.countCardCredited += 1;
      this.$buefy.toast.open({
        message: 'Carte créditée avec succès',
        type: 'is-success',
      });
      this.codeCard = '';
    } catch (error: any) {
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'error' } });
      this.$buefy.toast.open({
        message: 'Une erreur est survenue lors de la créditation de la carte',
        type: 'is-danger',
      });
    }
  }
}

