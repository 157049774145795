
import { Component, Vue } from 'vue-property-decorator';
import CardCreditedSuccess from '@/components/CardCreditedSuccess.vue';
import CardCreditedFailure from '@/components/CardCreditedFailure.vue';

@Component({
  components: {
    CardCreditedSuccess,
    CardCreditedFailure,
  },
})
export default class CardBurnResult extends Vue {
  public result: string | undefined = undefined;

  created(): void {
    console.log(`Result: ${this.$route.query.result}`);
    this.result = String(this.$route.query.result);
  }
}
